import Link from "next/link";
import { Col, Image } from "antd";
import { useState } from "react";

export default function CatalogueCard({ data, userAgent }) {
  const { isDesktop } = userAgent;
  let imageSrc = data?.cdn_link + "%3Fwidth%3D440";
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    imageSrc = data?.cdn_link
  };
  const onImageLoad = () => {
    setImageLoaded(true)
  }
  return (
    <Col
      className={
        !isDesktop
          ? "paddingRight5 paddingLeft5"
          : "paddingRight10 paddingLeft10"
      }
    >
      <Link
        className="width100"
        href={data?.brand_handle ? `${data?.brand_handle}` : ""}
      >
        <div
          className="catalogue-card"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            width="100%"
            height={imageLoaded ? "100%" : "300px"}
            preview={false}
            alt={data?.cdn_link && data?.cdn_link.split("/").pop()}
            src={imageSrc}
            onLoad={onImageLoad}
            onError={handleImageError}
          />
        </div>
      </Link>
    </Col>
  );
}
