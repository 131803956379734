

import { Carousel, Row, Col } from "antd";
import "./CataloguesCarousel.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "@/components/shared/Buttons/CarouselButtons/CarouselButtons";
import CatalogueCard from "./CatalogueCard/CatalogueCard";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

export default function CataloguesCarousel({ data, userAgent }) {
  const { isDesktop, isMobile } = userAgent;

  const settings = {
    speed: 500,
    swipe: true,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    arrows: !isMobile,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
    slidesToShow: !isDesktop ? 1.5 : 3,
  };

  return (
    <Row
      align="middle"
      justify="center"
      className={`CataloguesCarousel catalogues-carousel-container ${!isDesktop ? "paddingTop10 paddingBottom10" : ""
        }`}
    >
      <Col span={24} className="sectionHeadingContainer">
        <h2
          style={{ margin: "0px" }}
          className={
            !isDesktop ? "sectionHeadingMobileBold" : "sectionHeadingBold"
          }
        >
          {data?.main_title}
        </h2>
      </Col>

      <Col
        span={24}
        className={`carousel_container ${!isDesktop ? "padding0 paddingLeft5" : "paddingTop20 marginBottom35"
          }`}
      >
        <div>
          <Carousel {...settings}>
            {data?.catalogue_list?.map((cat, index) => (
              <CatalogueCard data={cat} key={index} userAgent={userAgent} />
            ))}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
}
