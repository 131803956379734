import Link from "next/link";
import { useState } from "react";
import { Col, Carousel, Image } from "antd";
import "./BannerSwiper.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "@/components/shared/Buttons/CarouselButtons/CarouselButtons";

export default function BannerSwiper({ data }) {
  const [isHovered, setIsHovered] = useState(false);

  const slideData =
    data?.slides?.length > 0
      ? Array.from({ length: Math.ceil(data.slides.length / 3) }, (_, i) =>
        data.slides.slice(i * 3, i * 3 + 3)
      )
      : [];

  const settings = {
    speed: 500,
    dots: true,
    swipe: true,
    arrows: true,
    infinite: true,
    autoplay: !isHovered,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    variableWidth: true,
    adaptiveHeight: true,
    pauseOnHover: true,
    swipeToSlide: true,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
  };



  return (
    <Col className="BannerSwiper margin15" span={24}>
      <div
        className="carousel_container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* {slideData.length === 0 ? (
          <div style={{ height: "400px", width: "100%", backgroundColor: "#f2f3f7" }} />
        ) : ( */}
        <Carousel {...settings}>
          {slideData.map((group, index) => (
            <div key={index} className="slide-group">
              {group.map((banner, key) => (
                <Link href={banner?.link} className="group-item" key={key}>
                  <div
                    style={{
                      position: "relative",
                      height: "470px",
                      width: "100%",
                    }}
                  >
                    <Image
                      preview={false}
                      key={key}
                      width="auto"
                      height="100%"
                      src={banner?.img}
                      alt={
                        banner?.banner_slider_alt_text ||
                        banner?.img.split("/").pop()
                      }
                      style={{
                        display: "block",
                      }}
                    />
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </Carousel>
        {/* )} */}
      </div>
    </Col>
  );
}
