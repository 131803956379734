
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
// import Image from "next/image";
import { useEffect, useState } from "react";
import { Col, Row, Space, Typography, Image } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./ProductsGroupComp.scss";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ProductsGroupComp(props) {
  //? Hooks ---------------------------------------------------------------------->
  const { isDesktop, isMobile } = props?.userAgent;

  //? States --------------------------------------------------------------------->
  // const [sectionData, setSectionData] = useState(props?.data?.collections);
  const [openPreview, setOpenPreview] = useState(false);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  //? Functions ------------------------------------------------------------------>
  const handlePreview = () => {
    setOpenPreview(true);
  };

  useEffect(() => {
    if (props?.data?.collections?.length) {
      setLoading(false);
    }
  }, [props?.data?.collections]);

  return (
    <Row
      className={
        !isDesktop
          ? "ProductsGroupComp padding0"
          : "ProductsGroupComp paddingTop15 paddingBottom10"
      }
      align="middle"
      justify="center"
    >
      {props?.data?.title ? (
        <Col span={24} className="sectionHeadingContainer">
          <h2
            className={
              !isDesktop ? "sectionHeadingMobileBold" : "sectionHeadingBold "
            }
          >
            {props?.data?.title}
          </h2>
        </Col>
      ) : null}

      <Row
        className={
          isDesktop
            ? "paddingRight30 paddingLeft30 paddingBottom10 flexEvenly width100"
            : "product-group-grid"
        }
        style={{ justifyContent: "center" }}
      >
        {loading ? (
          <div style={{ display: "flex", marginBottom: "10px" }}>
            {[1, 2, 3].map((index) => (
              <div key={index} style={{ flex: 1, marginRight: 16 }}>
                <CustomSkeletonBox width={100} height={400} />
              </div>
            ))}
          </div>
        ) : (
          props?.data?.collections?.map((item, index) => (
            // <Link href={item[0]?.handle ?? ""}>
            <Image.PreviewGroup key={index}>
              <Col key={index}>
                <Row
                  style={{
                    width: "100%",
                    display: "flex",
                    position: "relative",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Col flex={isMobile ? "30%" : "70%"}>
                    <Link
                      href={
                        item?.collection?.selector
                          ? "" + item?.collection?.selector
                          : ""
                      }
                    >
                      <LazyLoadImage
                        alt={item.title}
                        width={isMobile ? 240 : 310}
                        height={isMobile ? 420 : 420}
                        src={
                          item?.products[0]?.images[0]
                            ? item?.products[0]?.images[0]
                            : ""
                        }
                        loading="lazy"
                      />
                    </Link>

                    {/* Collection Title & Logo  */}

                    <Link
                      href={
                        item?.collection?.selector
                          ? "" + item?.collection?.selector
                          : ""
                      }
                      style={{
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        height: "55px",
                        padding: "5px",
                        position: "absolute",
                        background: "#00000099",
                      }}
                    >
                      <Col span={24}>
                        <Row align="middle" justify="space-between">
                          <Col flex="30%">
                            <LazyLoadImage
                              width={60}
                              alt={item.title}
                              src={item.brand_logo}
                              onClick={handlePreview}
                              loading="lazy"
                            />
                          </Col>
                          <Col flex="70%">
                            <Row>
                              <Text
                                style={{
                                  color: "#fff",
                                  fontSize: "14px",
                                  margin: "0px",
                                }}
                              >
                                {item?.title}
                              </Text>
                            </Row>
                            <Row>
                              <Text
                                style={{
                                  color: "#fff",
                                  fontWeight: "100",
                                  fontSize: "13px",
                                }}
                              >
                                {item?.description}
                              </Text>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Link>

                    {/* Image Viewer */}
                    {openPreview && (
                      <div style={{ display: "none" }}>
                        {item?.products?.slice(4)?.map((product, index) => {
                          return (
                            <LazyLoadImage
                              key={index}
                              width={100}
                              alt={item.title}
                              src={product?.images[0]}
                              loading="lazy"
                            />
                          );
                        })}
                      </div>
                    )}
                  </Col>

                  <Col
                    flex={isMobile ? "20%" : "23%"}
                    style={{ position: "relative" }}
                  >
                    <Space
                      direction="vertical"
                      className="three_imgs_container"
                      style={{
                        position: "relative",
                        width: !isDesktop && "86px",
                        paddingLeft: isDesktop ? "10px" : "5px",
                      }}
                    >
                      {item?.products?.map(
                        (product, index) =>
                          index > 0 &&
                          index <= 3 && (
                            <div
                              key={index}
                              style={{ position: "relative" }}
                              className={`${index === 3 ? "img_mask ImageCount" : ""
                                }`}
                              onClick={() =>
                                setPreviewVisible(!isPreviewVisible)
                              }
                            >
                              {index === 3 && (
                                <Link
                                  href={
                                    index !== 3
                                      ? "" + item?.collection?.selector
                                      : "" + item?.collection?.selector
                                  }
                                  className="gray_background"
                                ></Link>
                              )}

                              <Link
                                href={
                                  index !== 3
                                    ? "" + item?.collection?.selector
                                    : "" + item?.collection?.selector
                                }
                              >
                                <LazyLoadImage
                                  width={86}
                                  height={133}
                                  alt={item.title}
                                  className="img_mask"
                                  src={product?.images[0]}
                                  onClick={handlePreview}
                                  loading="lazy"
                                />
                              </Link>

                              {index === 3 && (
                                <Text
                                  className={
                                    isDesktop
                                      ? "img_mask_tag"
                                      : "img_mask_tag img_mask_tag_mobile"
                                  }
                                  onClick={() =>
                                    setPreviewVisible(!isPreviewVisible)
                                  }
                                >
                                  +{item?.products?.length - 4}
                                </Text>
                              )}
                            </div>
                          )
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Image.PreviewGroup>
            //  </Link>
          ))
        )}
      </Row>
    </Row>
  );
}
