import { Col, Carousel, Image } from "antd";
import Link from "next/link";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState, useEffect } from "react";

// Assets
import "./BannerCarousel.scss";

export default function BannerCarousel({ data }) {
  const [imageSrcs, setImageSrcs] = useState(
    () => data?.slides?.map((slide) => `${slide.img}%3Fwidth%3D440`) || []
  );

  const settings = {
    speed: 1000,
    autoplaySpeed: 3000,
    dots: true,
    swipe: true,
    autoplay: true,
    infinite: true,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnFocus: true,
  };

  const handleImageError = (index) => {
    setImageSrcs((prevImageSrcs) => {
      const newImageSrcs = [...prevImageSrcs];
      newImageSrcs[index] = data.slides[index].img;
      return newImageSrcs;
    });
  };

  return (
    <Col className="BannerCarousel banner_carousel">

      <Carousel {...settings}>
        {data.slides.map((slide, index) => (
          <Link key={index} href={slide?.link} passHref>
            <Image
              preview={false}
              alt={
                slide?.banner_slider_alt_text || slide?.img?.split("/").pop()
              }
              src={imageSrcs[index]}
              width="100%"
              className="carousel-image"
              onError={() => handleImageError(index)}
            // beforeLoad={() => (
            //   <div
            //     style={{
            //       width: "100%",
            //       height: "300px",
            //       backgroundColor: "#f0f0f0",
            //     }}
            //   />
            // )}
            />
          </Link>
        ))}
      </Carousel>

    </Col>
  );
}
