//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
// import Image from "next/image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Row, Image, Typography } from "antd";
import { useState } from "react";
//? Library Constants ---------------------------------------------------------->
const { Title } = Typography;

export default function BrandsCollection(props) {
  // const [imageLoaded, setImageLoaded] = useState(false);

  const { isDesktop } = props?.userAgent;
  // const onImageLoad = () => {
  //   setImageLoaded(true);
  // };
  return (
    <Row className="BrandsCollection ">
      {props?.data?.title ? (
        <Col span={24} className="sectionHeadingContainer padding0">
          <h2
            className={
              !isDesktop
                ? "sectionHeadingMobileBold "
                : "sectionHeadingBold margin0"
            }
          >
            {props?.data?.title}
          </h2>
        </Col>
      ) : null}

      <Col span={24}>
        <Row align="middle" justify="center" className="gap10">
          {props?.data?.brands?.map((item, index) => (
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={7}
              xs={7}
              key={index}
              style={{
                // margin: "5px",
                // height: "120px",
                // display: "flex",
                background: "#ffffff",
                // alignItems: "center",
                // justifyContent: "center",
              }}
              className="flexCenter hoverEffect padding20 backgroundLight"
            >
              <Link
                href={item?.handle ? item?.handle : ""}
                className="width100 flexCenter displayBlock"
              >
                {/* {!imageLoaded && (
                  <div
                    style={{
                      width: "100%",
                      height: isDesktop ? "100px" : "75px",
                      backgroundColor: "#e6e4e4",
                      position: "relative",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                      display: imageLoaded ? "none" : "block",
                    }}
                  />
                )} */}
                <Image
                  width="100%"
                  height="auto"
                  alt={item?.handle}
                  // onLoad={onImageLoad}
                  preview={false}
                  src={item?.logo}
                />

                {/* <Image
                  priority
                  width={111}
                  height={50}
                  src={item?.logo}
                  alt="Brand Logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                /> */}
              </Link>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}
