import Link from "next/link";
import { Col, Image, Row, Typography } from "antd";
import { useState } from "react";

const BannerSection = ({ data, userAgent }) => {
  const { isDesktop } = userAgent;

  return (
    <Row justify="center" align="middle" className="Banner BannerSection">
      {data?.main_title && (
        <Col span={24} className="sectionHeadingContainer">
          <h2
            className={
              !isDesktop
                ? "sectionHeadingMobileBold textCenter"
                : "sectionHeadingBold textLeft"
            }
          >
            {data?.main_title}
          </h2>
        </Col>
      )}

      <Col span={24} className="flexCenter width100">
        {data?.banner?.map((banner, index) => {
          const [imgSrc, setImgSrc] = useState(
            `${banner?.img}${isDesktop ? "%3Fwidth%3D1300" : "%3Fwidth%3D720"}`
          );

          const handleImageError = () => {
            setImgSrc(banner?.img);
          };

          return (
            <Link href={banner?.link} key={index} className="width100">
              <Image
                preview={false}
                src={imgSrc}
                onError={handleImageError}
                alt={banner?.banner_alt_text || banner?.img.split("/").pop()}
                className="desktop_banner width100"
                width="100%"
                height="auto"
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "#e6e4e4",
                }}
              />
            </Link>
          );
        })}
      </Col>
    </Row>
  );
};

export default BannerSection;
