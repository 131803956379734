
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { Col, Typography, Carousel } from "antd";

//? Assets --------------------------------------------------------------------->
import "./TabsSection.scss";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function TabsSection({ data, userAgent }) {
  const { isDesktop } = userAgent;

  const settings = {
    swipe: true,
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: !isDesktop ? 1 : 3,
    autoplay: !isDesktop ? true : false,
    infinite: !isDesktop ? true : false,
    speed: 1000,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  return (
    <Col
      className={
        !isDesktop
          ? "TabsSection tabs_section mobile_tabs_section "
          : "TabsSection tabs_section"
      }
    >
      <Carousel {...settings}>
        {data?.tabs?.map((tab, index) => (
          <Col key={index}>
            <div
              style={{ background: tab?.background_color }}
              className="tabs_container"
            >
              <Link href={tab?.link}>
                <Text style={{ color: tab?.text_color }}>{tab?.text}</Text>
              </Link>
            </div>
          </Col>
        ))}
      </Carousel>
    </Col>
  );
}
