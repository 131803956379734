import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Col, Typography } from "antd";
import "./CategoriesHeader.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const { Text } = Typography;

export default function CategoriesHeader({ data, userAgent }) {
  // This example assumes `data` is not dynamically changing frequently.
  // If it does, you might need to adjust this to handle updates appropriately.
  // const [imageSources, setImageSources] = useState({});

  // useEffect(() => {
  //   const initialImageSources = data?.header_list?.reduce(
  //     (acc, item, index) => {
  //       acc[index] = item.category_image + "%3Fwidth%3D150"; // Initial image source
  //       return acc;
  //     },
  //     {}
  //   );
  //   setImageSources(initialImageSources);
  // }, [data?.header_list]);

  // const handleImageError = (index) => {
  //   setImageSources((prevSources) => ({
  //     ...prevSources,
  //     [index]: data.header_list[index].category_image,
  //   }));
  // };

  return (
    <Col className="CategoriesHeader">
      <div className="category-header-inner">
        {data?.header_list?.map((catHead, index) => (
          <Link
            href={catHead?.handle}
            className="flexCenter flexColumn gap5"
            key={index}
            style={{ minWidth: window.innerWidth >= 768 ? "12%" : "20%" }}
          >
            <LazyLoadImage
              width={70}
              height={70}
              src={catHead?.category_image}
              alt={catHead?.category_image_alt_text}
              style={{ objectFit: "cover" }}
              // onError={() => handleImageError(index)}
            />
            <Text className="font12">{catHead?.name}</Text>
          </Link>
        ))}
      </div>
    </Col>
  );
}
