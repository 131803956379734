
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
// import Image from "next/image";
import { Image, Space, Typography } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./MultiUsableComp.scss";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ShopByCategoryCard({
  userAgent,
  href,
  imagesrc,
  altText,
  title,
}) {
  const { isMobile, isDesktop } = userAgent;

  const [imageSrc, setImageSrc] = useState(
    imagesrc + `${isMobile ? "%3Fwidth%3D150" : "%3Fwidth%3D200"}`
  );
  // const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageError = () => {
    setImageSrc(imagesrc);
  };

  // const onImageLoad = () => {
  //   setImageLoaded(true);
  // };

  return (
    <Space direction="vertical" align="center" justify="center">
      {/* {!imageLoaded && (
        <div
          style={{
            width: "100%",
            height: isDesktop ? "175px" : "170px",
            backgroundColor: "#e6e4e4",
            // position: "relative",
            top: 0,
            left: 0,
            zIndex: 1,
            display: imageLoaded ? "none" : "block",
          }}
        />
      )} */}
      <Link href={href} className="width100 displayBlock">
        <Image
          width="100%"
          height="auto"
          onError={handleImageError}
          src={imageSrc}
          // onLoad={onImageLoad}
          preview={false}
          alt={altText}
        // style={{ visibility: !imageLoaded && "hidden" }}
        />
      </Link>

      <Text strong>{title}</Text>
    </Space>
  );
}
