"use client";

//? Library Imports ------------------------------------------------------------>
import Cookies from "js-cookie";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../redux/Slices/multiLocationSlice";

import dynamic from "next/dynamic";

//? Assets --------------------------------------------------------------------->
import "../../components/Homepage/HomePage.scss";

//? Modules -------------------------------------------------------------------->
import TabsSection from "@/components/Homepage/TabsSection/TabsSection";
import BannerSwiper from "@/components/Homepage/BannerSwiper/BannerSwiper";
import BannerCarousel from "@/components/Homepage/BannerCarousel/BannerCarousel";
import CategoriesHeader from "@/components/Homepage/CategoriesHeader/CategoriesHeader";

//? Redux Reducers ------------------------------------------------------------->
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";
import CataloguesCarousel from "@/components/Homepage/CataloguesCarousel/CataloguesCarousel";
import ProductsCarousel from "@/components/shared/ProductsCarousel/ProductsCarousel";
import BannerSection from "@/components/Homepage/BannerSection/BannerSection";
import BrandsCollection from "@/components/Homepage/BrandsCollection/BrandsCollection";
import MultiUsableComp from "@/components/Homepage/MultiUsableComp/MultiUsableComp";
import FilterByGenderSection from "@/components/Homepage/FilterByGenderSection/FilterByGenderSection";
import ProductsGroupComp from "@/components/Homepage/ProductsGroupComp/ProductsGroupComp";

const Homepage = ({
  storeId,
  data,
  userAgent,
  defaultCookiesCurrencyValue,
  defaultCookiesCountryValue,
  myIpAddress
}) => {
  const dispatch = useDispatch();
  const [homePageData, setHomePageData] = useState(data);

  const { isMobile, isTablet, isDesktop } = userAgent;

  const initializeCookies = useCallback(() => {
    const cookieOptions = { expires: 365 };
    Cookies.get("address") ||
      Cookies.set("address", myIpAddress.trim(), cookieOptions);
    Cookies.get("defaultCurrencyId") ||
      Cookies.set("defaultCurrencyId", storeId, cookieOptions);
    Cookies.get("defaultCurrencyName") ||
      Cookies.set(
        "defaultCurrencyName",
        defaultCookiesCurrencyValue,
        cookieOptions
      );
    Cookies.get("defaultCountry") ||
      Cookies.set("defaultCountry", defaultCookiesCountryValue, cookieOptions);
    Cookies.get("geoLocation") ||
      Cookies.set("geoLocation", defaultCookiesCountryValue, cookieOptions);
  }, [storeId, defaultCookiesCurrencyValue, defaultCookiesCountryValue]);

  const fetchNextPage = useCallback(
    async (page) => {
      const apiURL = isDesktop
        ? `${process.env.NEXT_PUBLIC_BACKEND}/storefront/homepage-desktop-new?storefront=${storeId}&page=${page}`
        : `${process.env.NEXT_PUBLIC_BACKEND}/storefront/homepage-mobile-new?storefront=${storeId}&page=${page}`;
      try {
        const response = await fetch(apiURL);
        if (!response.ok) throw new Error("API call failed");

        const pageData = await response.json();
        setHomePageData((prevData) => ({
          ...prevData,
          results: [...prevData.results, ...pageData.results],
          next: pageData.next,
        }));

        if (pageData.next) {
          const nextPageNumber = new URL(pageData.next).searchParams.get(
            "page"
          );
          fetchNextPage(nextPageNumber);
        }
      } catch (error) {
        console.error("Error fetching page data:", error.message);
      }
    },
    [isDesktop, storeId]
  );

  useEffect(() => {
    initializeCookies();
    if (isMobile) {
      fetchNextPage(2);
    }
  }, [initializeCookies]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const sections = isMobile
    ? homePageData?.results
    : data?.homepage;

  return (
    <div
      className="Homepage deployment-test"
      onMouseOver={() => dispatch(setShowMegaMenu(false))}
    >
      {sections?.map((section, index) => {
        const { type } = section;
        const commonProps = {
          data: section,
          userAgent: { isMobile, isTablet, isDesktop },
        };

        return (
          <div key={index}>
            {type === "tabs_section" && <TabsSection {...commonProps} />}
            {type === "banner_slider" &&
              (!isMobile ? (
                <div className="marginBottom15">
                  <BannerSwiper {...commonProps} />
                </div>
              ) : (
                <BannerCarousel {...commonProps} />
              ))}
            {type === "catalogues_carousel" && (
              <div className="catalogues-carousel-wrapper">
                <CataloguesCarousel {...commonProps} />
              </div>
            )}
            {type === "products_carousel" && (
              <div className="product-carousel-main">
                <ProductsCarousel
                  {...commonProps}
                  data={
                    section?.select_type === "collections_section"
                      ? section?.collections[0]?.products
                      : section?.categories[0]?.products
                  }
                  type="homepage"
                />
              </div>
            )}
            {type === "banner_section" && <BannerSection {...commonProps} />}
            {type === "brands_slider" && <BrandsCollection {...commonProps} />}
            {type === "multi_usable_section" && (
              <MultiUsableComp {...commonProps} />
            )}
            {type === "category_filters" && (
              <FilterByGenderSection {...commonProps} />
            )}
            {type === "collections_section" && (
              <ProductsGroupComp {...commonProps} />
            )}
            {type === "categories_header_section" && !isDesktop && (
              <CategoriesHeader {...commonProps} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Homepage;
